import React from 'react'
import GoogleMapReact from 'google-map-react'

const defaultProps = {
  center: {
    lat: 50.509351,
    lng: 22.316460,
  },
  zoom: 12,
}
const mapOptions = {
  styles: [
    {
        "stylers": [
            {
                "hue": "#ff1a00"
            },
            {
                "invert_lightness": true
            },
            {
                "saturation": -100
            },
            {
                "lightness": 33
            },
            {
                "gamma": 0.5
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#2D333C"
            }
        ]
    }
]

};
const AnyReactComponent = ({ text }) => <div className="marker">{text}</div>

const GoogleMap = () => (

  <div className="p-0" style={{ height: '60vh', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyBemlVhFhe2d9pxZ6aJHz-h_UYYijEtN0c' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      options={mapOptions}
    >
      <AnyReactComponent
        lat={50.509351}
        lng={22.316460}
        text={''}
      />
    </GoogleMapReact>
  </div>
)

export default GoogleMap