import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cta from "../components/cta"
import GoogleMap from '../components/google-map';
const SecondPage = () => (
  
  <Layout>
    <SEO title="Kontakt - skontaktuj się z nami" description="Nisk, Jarocin, Ulanów, Rudnik, Kurtyna Wielka, Zarzecze - odwiedź nas" />
    <Header title="KONTAKT" />
    <section className="contact inset ">
      <div className="container">
        <div className="row kontakt-row d-flex flex-column flex-md-row" style={{margin: "auto"} }>
            <div className="contact__box col-12 col-xl-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="512px"
                viewBox="0 0 512.00069 512"
                width="512px"
              >
                <g>
                  <path
                    d="m316 260c-5.519531 0-10 4.480469-10 10s4.480469 10 10 10 10-4.480469 10-10-4.480469-10-10-10zm0 0"
                    data-original="#000000"
                    class="active-path"
                    data-old_color="#000000"
                    fill="#0e0d0de7"
                  />
                  <path
                    d="m102.167969 369.785156-100 126c-2.386719 3.003906-2.839844 7.109375-1.171875 10.5625 1.667968 3.457032 5.167968 5.652344 9.003906 5.652344h492c3.835938 0 7.335938-2.195312 9.003906-5.652344 1.671875-3.453125 1.214844-7.558594-1.171875-10.5625l-100-126c-1.894531-2.390625-4.78125-3.785156-7.832031-3.785156h-87.597656l74.785156-117.296875c17.542969-26.300781 26.8125-56.972656 26.8125-88.703125 0-88.222656-71.773438-160-160-160s-160 71.777344-160 160c0 31.730469 9.269531 62.398438 26.8125 88.703125l74.785156 117.296875h-87.597656c-3.050781 0-5.933594 1.394531-7.832031 3.785156zm-37.335938 79.214844h60.464844l-34.125 43h-60.46875zm145.519531-63 27.414063 43h-71.0625l34.128906-43zm91.300782 0h9.519531l34.125 43h-71.058594zm59.519531 63 34.125 43h-278.59375l34.128906-43zm59.660156 43-34.128906-43h60.46875l34.125 43zm10.464844-63h-60.464844l-34.128906-43h60.46875zm-291.789063-191.3125c-15.378906-23.023438-23.507812-49.886719-23.507812-77.6875 0-77.195312 62.804688-140 140-140s140 62.804688 140 140c0 27.800781-8.128906 54.664062-23.503906 77.6875-.042969.058594-.078125.117188-.117188.175781-6.566406 10.300781-111.320312 174.605469-116.378906 182.535157-12.722656-19.957032-103.421875-162.214844-116.378906-182.535157-.035156-.058593-.074219-.117187-.113282-.175781zm35.789063 148.3125-34.125 43h-60.46875l34.128906-43zm0 0"
                    data-original="#000000"
                    class="active-path"
                    data-old_color="#000000"
                    fill="#0e0d0de7"
                  />
                  <path
                    d="m256 260c54.898438 0 100-44.457031 100-100 0-55.140625-44.859375-100-100-100s-100 44.859375-100 100c0 55.558594 45.117188 100 100 100zm0-180c44.113281 0 80 35.886719 80 80 0 44.523438-36.175781 80-80 80-43.835938 0-80-35.476562-80-80 0-44.113281 35.886719-80 80-80zm0 0"
                    data-original="#000000"
                    class="active-path"
                    data-old_color="#000000"
                    fill="#0e0d0de7"
                  />
                  <path
                    d="m298.121094 294.125c-4.726563-2.851562-10.875-1.328125-13.726563 3.402344l-36.960937 61.320312c-2.851563 4.730469-1.328125 10.875 3.402344 13.726563 4.75 2.863281 10.886718 1.308593 13.726562-3.402344l36.960938-61.320313c2.851562-4.730468 1.328124-10.875-3.402344-13.726562zm0 0"
                    data-original="#000000"
                    class="active-path"
                    data-old_color="#000000"
                    fill="#0e0d0de7"
                  />
                </g>{" "}
              </svg>
              <p>
                <p className="">
                  <br />
                  Dąbrówka 87g 
                </p>
                <p>37-410 Ulanów</p>
              </p>
            </div>
            <div className="contact__box col-12 col-xl-4">
            <svg  version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 511.996 511.996" width="512px" height="512px" class=""><g><g>
	<g>
		<path d="M324.263,0.003c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533c94.106,0,170.667,76.561,170.667,170.667    c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533C511.996,84.218,427.781,0.003,324.263,0.003z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#0E0D0D"/>
	</g>
</g><g>
	<g>
		<path d="M324.263,51.203c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533c65.877,0,119.467,53.589,119.467,119.467    c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533C460.796,112.455,399.544,51.203,324.263,51.203z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#0E0D0D"/>
	</g>
</g><g>
	<g>
		<path d="M324.263,102.403c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533c37.641,0,68.267,30.626,68.267,68.267    c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533C409.596,140.683,371.316,102.403,324.263,102.403z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#0E0D0D"/>
	</g>
</g><g>
	<g>
		<path d="M461.76,320.216c-23.219-3.055-46.114-8.755-68.045-16.922c-21.231-7.962-45.363-2.825-61.525,13.124l-26.974,26.914    c-56.559-33.348-103.808-80.503-137.242-136.951l26.991-26.931c15.957-16.102,21.112-40.209,13.133-61.397    c-8.183-21.897-13.892-44.749-16.964-67.994c-4.088-28.817-29.047-50.167-58.223-50.057H58.253c-1.741,0-3.473,0.077-5.197,0.23    C37.551,1.632,23.514,8.98,13.539,20.909C3.563,32.847-1.164,47.951,0.244,63.576c8.269,77.713,35.149,153.472,77.687,219.008    c38.613,60.655,91.162,113.092,151.868,151.595c65.417,42.342,140.971,69.163,218.641,77.585c1.758,0.154,3.524,0.23,5.308,0.23    c0.051,0,0.111,0,0.162,0c32.154-0.137,58.197-26.351,58.069-58.411v-74.402C512.653,349.69,491.124,324.346,461.76,320.216z     M127.996,51.203H58.262c-3.174,0.256-4.736,1.707-5.436,2.543c-0.435,0.529-1.843,2.398-1.647,4.557    c3.098,29.09,8.73,57.506,16.725,84.318c1.348,4.514-1.22,9.267-5.734,10.615c-0.811,0.247-1.63,0.358-2.441,0.358    c-3.678,0-7.074-2.398-8.175-6.093c-8.303-27.819-14.14-57.267-17.365-87.535c-0.768-8.602,3.618-14.865,5.538-17.161    c2.389-2.859,7.706-7.791,16.393-8.576c0.708-0.06,1.417-0.094,2.125-0.094h69.743c4.719,0,8.533,3.831,8.533,8.542    C136.53,47.388,132.707,51.203,127.996,51.203z M477.846,453.592c0.026,6.451-2.466,12.544-7.014,17.135    c-4.54,4.582-10.598,7.117-17.058,7.142c-0.205,0-1.621-0.034-2.33-0.102c-30.345-3.294-59.554-9.114-86.955-17.323    c-4.514-1.357-7.083-6.11-5.726-10.624c1.357-4.514,6.118-7.083,10.624-5.726c26.419,7.91,54.588,13.525,83.746,16.691    l0.794,0.017c1.69-0.009,3.473-0.751,4.804-2.099c1.34-1.348,2.074-3.157,2.065-5.077l0.008-69.623    c0-4.71,3.823-8.533,8.533-8.533s8.533,3.823,8.533,8.533L477.846,453.592z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#0E0D0D"/>
	</g>
</g></g> </svg>
              <p>
                <p className="mt-5">
               <a href="tel:+667102228">667-102-228</a>
                </p>
              
              </p>
            </div>
            <div className="contact__box col-12 col-xl-4">
            <svg  version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 480 480"  width="512px" height="512px"><g><g>
	<g>
		<path d="M440,80H40C17.92,80.026,0.026,97.92,0,120v240c0.026,22.08,17.92,39.974,40,40h400c22.08-0.026,39.974-17.92,40-40V120    C479.974,97.92,462.08,80.026,440,80z M464,360c0,13.255-10.745,24-24,24H40c-13.255,0-24-10.745-24-24V120    c0-13.255,10.745-24,24-24h400c13.255,0,24,10.745,24,24V360z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#0e0d0de7"/>
	</g>
</g><g>
	<g>
		<path d="M446.856,115.88c-2.276-3.786-7.189-5.011-10.976-2.736L260.576,218.32c-12.658,7.624-28.494,7.624-41.152,0    L44.12,113.144c-3.828-2.207-8.72-0.893-10.927,2.934c-2.151,3.73-0.964,8.494,2.687,10.778L211.2,232.04    c17.722,10.654,39.878,10.654,57.6,0l175.32-105.184C447.906,124.58,449.131,119.667,446.856,115.88z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#0e0d0de7"/>
	</g>
</g><g>
	<g>
		<path d="M40,144c-4.418,0-8,3.582-8,8v208c0,4.418,3.582,8,8,8s8-3.582,8-8V152C48,147.582,44.418,144,40,144z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#0e0d0de7"/>
	</g>
</g><g>
	<g>
		<path d="M440,144c-4.418,0-8,3.582-8,8v208c0,4.418,3.582,8,8,8s8-3.582,8-8V152C448,147.582,444.418,144,440,144z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#0e0d0de7"/>
	</g>
</g></g> </svg>

              <p>
                <p className="mt-5">
                 
                kontakt.pojedzone@gmail.com
                </p>
             
              </p>
            </div>
          <div className="col-12 p-0 mt-5"><GoogleMap/></div>
        </div>
      </div>
    </section>
    <Cta />
  </Layout>
  
)

export default SecondPage
